<template>
  <CRow>
    <div>
      <CModal
          title="Warning"
          size="lg"
          :show.sync="largeModal"
          :no-close-on-backdrop="true"
          color="primary"
      >
        <CCardBody>
          <CRow>
            <CCol md="12">
              <c-textarea
                  v-model="reason"
                  :rows="5"
                  :cols="10"
                  placeholder="Add Reason"
              ></c-textarea>
            </CCol>
            <CCol md="6">
              <div class="form-group">
                <CButton
                    color="primary"
                    v-c-tooltip="$lang.report.crud.sent_warning"
                    v-on:click="sentWarning"
                >
                  Submit
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
        <template #footer style="display: none">
          <CButton
              @click="darkModal = false"
              color="danger"
              style="display: none"
          >Discard
          </CButton
          >
          <CButton
              @click="darkModal = false"
              color="success"
              style="display: none"
          >Accept
          </CButton
          >
        </template>
      </CModal>
    </div>
    <CCol col="12" lg="12">
      <CAlert color="success" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <strong>{{ $lang.report.crud.view }}</strong>
          <CButton v-on:click="sentWarningModel()" color="warning" style="float:right;width:20%;">
            Sent Warning
          </CButton>
        </CCardHeader>
        <CCardBody>
          <h2>Warning History</h2>
          <v-server-table :columns="columns" :url="url" :options="options" ref="myTable">
          </v-server-table>
          <h2>Reporting</h2>
          <table class="table table-bordered">
            <tr v-for="(item, index) in reporting">
              <th>{{ index }}</th>
              <td>{{ item }}</td>
            </tr>
          </table>
          <h2>Reported</h2>
          <table class="table table-bordered">
            <tr v-for="(items, i) in report">
              <th>{{ i }}</th>
              <td>{{ items }}</td>
            </tr>
          </table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {company} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
Vue.use(VueTelInput);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
import {ServerTable} from "vue-tables-2";

Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);
Vue.use(ServerTable, {}, false);

export default {
  name: "CompanyCreate",
  mixins: [Mixin],
  data() {
    return {
      bindProps: {
        mode: "international",
        placeholder: "Contact No.",
        defaultCountry: "IN",
        dropdownOptions: {
          showFlags: true,
        },
        validCharactersOnly: true,
      },
      submitted: false,
      amountField: "",
      persontageField: "",
      userField: "",
      largeModal: false,
      reason: "",
      companyField: "",
      err_msg: "",
      // date: "",
      dismissCountDown: 0,
      self: this,
      url: '',
      module: company,
      reporting: [],
      report: [],
      editId: this.$route.params.id,
      columns: ['reason'],
      options: {
        headings: {
          reason: this.$lang.report.table.reason,
        },
        editableColumns: [],
        sortable: [],
        filterable: []
      },
    };
  },
  created() {
    let self = this;
    self.url = "entity/report/warning/history/list/" + this.$route.params.id;
    store.commit("showLoader", false);
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;

    axios
        .get('entity/report/reporting/list/' + self.editId)
        .then((response) => {
          self.reporting = response.data.data;
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });

    axios
        .get('entity/report/reported/list/' + self.editId)
        .then((response) => {
          self.report = response.data.data;
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
  },
  methods: {
    sentWarningModel() {
      let self = this;
      self.reason = '';
      self.largeModal = true;
    },
    sentWarning() {
      let self = this;
      const obj = {
        "userId": self.editId,
        "reason": self.reason,
      };
      self.largeModal = false;
      axios.post('entity/report/sent/warning', obj)
          .then((response) => {
            self.err_msg = response.data.message;
            self.dismissCountDown = 10;
            this.$refs.myTable.refresh();
          })
          .catch(function (error) {
            store.commit("showLoader", false); // Loader Off
          });
    }
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
